import React from "react";
import "./style.scss";

class ProjectListItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			className: ""
		};
	}

	render() {
		return (
			<tr onMouseEnter={()=> this.setState({className: " hover"})} onMouseLeave={()=> this.setState({className: ""})} title={this.props.title} onClick={()=> this.props.onClick()} className={this.props.className + this.state.className}>
				{this.props.children}
			</tr>
		);
	}
}
export default ProjectListItem;
