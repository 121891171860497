import React from "react";
import Project from "../Project/Project.js";
import uuid from "js-uuid";
import NotificationSystem from "react-notification-system";
import utils from "../../utils/utils.js";
import "./style.scss";
import deleteIcon from "../../images/icons/icon-delete.png";
import bannerboyLogo from "../../images/logo-bannerboy.png";
import EditableText from "../EditableText/EditableText.js";
import dataStructures from "../../dataStructures.js";
import SavingIndicator from "../SavingIndicator/SavingIndicator.js";
import ProjectListItem from "../ProjectListItem/ProjectListItem.js";

class App extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			projects: [],
			selectedProject: null,
			saving: false,
			clientInput: "Client", // these are the two input boxes for adding a project
			projectInput: "Project",
			orderBy: ""
		};

		this.loadProjects();
	}

	loadProjects() {
		let collectionRef = this.state.orderBy ? this.props.database.collection("projects").orderBy(this.state.orderBy).get() : this.props.database.collection("projects").get();
		return new Promise((resolve, reject)=> {
			collectionRef.then((snapshot) => {
				let projects = [];
				snapshot.forEach((doc)=> {
					projects.push(Object.assign(doc.data(), {id: doc.id}));
				});
				this.setState({projects: projects}, ()=> {
					if (window.location.hash) this.selectProject(window.location.hash.replace("#", ""));
					resolve();
				});
			});
		});
	}

	componentWillUpdate(nextProps, nextState) {
		// reload projects if orderBy has changed
		if (nextState.orderBy != this.state.orderBy) this.loadProjects();
	}

	componentDidMount() {
		window.addEventListener("popstate", this.onHashChange.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener("popstate", this.onHashChange);
	}

	onHashChange() {
		if (window.location.hash) {
			this.selectProject(window.location.hash.replace("#", ""));
		} else {
			this.closeProject();
		}
	}

	selectProject(id) {
		let project = this.projectExists(id);
		if (!project || this.state.saving) return;
		// set window location hash
		window.location.hash = "#" + id;
		this.setState({
			selectedProject: project
		});
	}

	closeProject() {
		window.location.hash = "";
		this.loadProjects();
		this.setState({
			selectedProject: null
		});
	}

	addProject(client, name) {
		this.saveProject(client, name).then((project) => {
			// notify
			this.notificationSystem.addNotification({
				message: "Successfully created project " + name,
				position: "bl",
				autoDismiss: 2,
				dismissible: false,
				level: "success"
			});

			// set state
			this.state.projects.push(project);
			this.setState({
				projects: this.state.projects,
				saving: false,
				clientInput: "Client",
				projectInput: "Project"
			}, ()=> {
				// select the newly created project
				this.selectProject(project.id);
			});

		});
	}

	saveProject(client, name, templateProject) {

		return new Promise((resolve, reject)=> {
			this.setState({saving: true});

			// create a fresh firestore document
			let doc = this.props.database.collection("projects").doc();

			// create project (or use the one provided)
			let project = templateProject ? Object.assign(templateProject, {
				name: name,
				client: client,
				nameLowerCase: name.toLowerCase(),
				clientLowerCase: client.toLowerCase()
			}) : dataStructures.project({
				name: name,
				client: client,
				id: doc.id,
				modified: Date.now(),
				modifiedBy: firebase.auth().currentUser.displayName
			});

			doc.set(project).then(()=> {
				resolve(project);
			});
		});
	}

	userRemoveProject(id) {
		if (!confirm("Are you sure?")) return;
		this.removeProject(id).then(()=> {
			// notify
			this.notificationSystem.addNotification({
				message: "Successfully deleted project " + name,
				position: "bl",
				autoDismiss: 2,
				dismissible: false,
				level: "success"
			});
		});
	}

	removeProject(id) {
		return new Promise((resolve, reject)=> {
			this.setState({saving: true});
			let doc = this.props.database.collection("projects").doc(id);
			let name = this.state.projects.find(project => { return project.id == id; }).name;
			doc.delete().then(()=> {
				this.loadProjects().then(() => {
					this.setState({saving: false});
					resolve();
				});
			});
		});
	}

	renameProject(project, id, client, projectName) {
		// if nothing changed, return
		if (client == project.client && projectName == project.name) return;
		// save new project, then remove old one
		this.saveProject(client, projectName, project).then((doc)=> {
			this.removeProject(id).then(()=> {
				// notify
				this.notificationSystem.addNotification({
					message: "Successfully renamed project to " + client + " - " + projectName,
					position: "bl",
					autoDismiss: 2,
					dismissible: false,
					level: "success"
				});
			});
		});
	}

	projectNameExists(name) {
		return this.state.projects.find(project => { return project.name == name; });
	}

	projectExists(id) {
		return this.state.projects.find(project => { return project.id == id; });
	}

	render() {
		let notificationStyle = {
			NotificationItem: {
				DefaultStyle: {
					margin: "50px",
					minHeight: "50px",
					fontSize: "18px"
				},
			}
		}

		let projectListView;
		if (!this.state.selectedProject) {
			projectListView = (
				<div id="project-list-dashboard">
					<div className="top-bar">
						<h1 className="title">Unit List Generator</h1>
						<div className="top-bar-center">
							<EditableText
								className="project-title"
								value={this.state.clientInput}
								onUpdate={(clientInput)=> this.setState({clientInput})}
							/>
							<h2 className="project-title"> - </h2>
							<EditableText
								className="project-title"
								value={this.state.projectInput}
								onUpdate={(projectInput)=> this.setState({projectInput})}
							/>
							<button onClick={()=> this.addProject(this.state.clientInput, this.state.projectInput)} className="button-add material-icons"></button>
						</div>
						<input onChange={(e)=> this.setState({search: e.target.value})} className="top-bar-search title-mid" type="text" placeholder="What are you looking for today?"></input>
					</div>
					<table id="project-list-view">
						<thead>
							<tr className="project-list-item title-small gray">
								<td onClick={()=> this.setState({orderBy: "clientLowerCase"})} className="project-list-column-left">CLIENT</td>
								<td></td>
								<td onClick={()=> this.setState({orderBy: "nameLowerCase"})} className="project-list-column-left">NAME</td>
								<td onClick={()=> this.setState({orderBy: "modified"})} className="project-list-column-right">DATE MODIFIED</td>
							</tr>
						</thead>
						<tbody>
							{
								this.state.projects.map((project, i) => {
									let search = this.state.search;
									if (search !== "" && (project.name.search(new RegExp(search, "gi")) == -1 && project.client.search(new RegExp(search, "gi")) == -1)) return;
									return (
										<ProjectListItem
											key={i}
											onClick={()=> this.selectProject(project.id)}
											title={`Last modified by: ${project.modifiedBy || ''}`}
											className="project-list-item title-mid">
											<td className="project-list-column-left">
												<EditableText
													className="title-mid gray"
													value={project.client}
													onUpdate={()=> {}}
													disabled={this.state.saving}
													onBlur={(value)=> this.renameProject(project, project.id, value, project.name)}
												/>
											</td>
											<td className="project-list-column-left">-</td>
											<td className="project-list-column-left">
												<EditableText
													className="title-mid"
													value={project.name}
													onUpdate={()=> {}}
													disabled={this.state.saving}
													onBlur={(value)=> this.renameProject(project, project.id, project.client, value)}
												/>
											</td>
											<td className="project-list-column-right">
												<p className="title-mid gray">{new Date(project.modified).toLocaleString()}</p>
												<img onClick={(e)=> { e.stopPropagation(); this.userRemoveProject(project.id); }} src={deleteIcon}/>
											</td>
										</ProjectListItem>
									);
								})
							}
						</tbody>
					</table>
				</div>
			);
		}
		return (
			<div id="app">
				{projectListView}
				{this.state.selectedProject != null ? <Project id={this.state.selectedProject.id} closeHandler={()=> this.closeProject()} database={this.props.database}/> : ""}

				<img id="logo-bannerboy" src={bannerboyLogo}/>
				<SavingIndicator saving={this.state.saving}/>
				<NotificationSystem
					ref={notificationSystem => window.notificationSystem = this.notificationSystem = notificationSystem }
					style={notificationStyle}
				/>
			</div>
		);
	}
}

export default App;
