import React from "react";
import Button from "../../Button/Button.js";
import "./style.scss";

class FieldItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this.props.value || ""
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		this.setState({value: event.target.value}, function() {
			this.props.onUpdate(this.state.value, this.props.index);
		});
	}

	componentWillReceiveProps(nextProps) {
		this.setState({value: nextProps.value || ""});
	}

	focusHandler(e) {
		// set cursor to end of text
		e.target.selectionStart = e.target.selectionEnd = e.target.value.length;
		this.props.onFocus(this.props.index);
	}

	render() {
		return (
			<div className="field-item">
				<input
					type="text"
					ref={input => this.inputField = input}
					value={this.state.value}
					onChange={this.handleChange} onPaste={(e)=> {
						e.preventDefault();
						this.props.onPaste(e, this.props.index);
						return false;
					}}
					onBlur={()=> this.props.onBlur(this.props.index)}
					onFocus={(e)=> this.focusHandler(e)}
				/>
			</div>
		);
	}
}
export default FieldItem;
