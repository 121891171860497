import React from "react";
import FieldItem from "./FieldItem/FieldItem.js";
import EditableText from "../EditableText/EditableText.js";
import utils from "../../utils/utils.js";
import Exception from "../Exception/Exception.js";
import "./style.scss";

class Field extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			focusIndex: null,
			name: this.props.name || "",
			values: this.props.values || [],
			exceptions: this.props.exceptions,
			bind: this.props.bind || "",
			uuid: this.props.uuid,
			cycle: this.props.cycle || ""
		};

		// array to store refs of the field items
		this.fieldItems = [];
	}

	handleNameChange(name) {
		this.setState({name}, this.sendStateUp);
	}

	addFieldItem(value) {
		this.state.values.push(typeof value === "string" ? value : "");
		this.setState({values: this.state.values, minimized: false}, ()=> {
			this.lastFieldItem.inputField.focus();
			this.sendStateUp();
		});
	}

	removeFieldItem(itemIndex) {
		this.state.values.splice(itemIndex, 1);
		this.setState({values: this.state.values, minimized: false}, ()=> {
			// if we are removing the last value, focus on the one above it
			if (itemIndex == (this.state.values.length) && this.lastFieldItem.inputField) this.lastFieldItem.inputField.focus();
			this.sendStateUp();
		});
	}

	handleItemUpdate(newState, itemIndex) {
		this.state.values[itemIndex] = newState;
		this.setState({values: this.state.values, minimized: false}, this.sendStateUp);
	}

	sendStateUp() {
		this.props.onUpdate(this.state);
	}

	pasteHandler(e, startIndex) {
		// get pasted text, format it and remove duplicates
		let pastedText = e.clipboardData.getData('text/plain');
		let values = pastedText.split(/[\n ,]/g).filter((value)=> {return value != ""});
		let uniqueValues = utils.unique(values);

		// notify user of removed duplicates
		let numDuplicates = values.length - uniqueValues.length;
		if (numDuplicates > 0) {
			window.notificationSystem.addNotification({
				message: "Removed " + numDuplicates + " duplicate values.",
				level: "info",
				dismissible: false,
				autoDismiss: 3,
				position: "bl"
			});
		}

		uniqueValues.forEach((value, i)=> {
			this.state.values[startIndex + i] = value;
		});
		this.setState({values: this.state.values, minimized: false}, this.sendStateUp);
		return false; // Prevent the default handler from running.
	}

	handleFinishEditTitle() {
		// user has pressed enter to finish edit the title, focus on first field item
		if (this.lastFieldItem) this.lastFieldItem.inputField.focus();
	}

	updateException(i, value) {
		this.state.exceptions[i] = value;
		this.setState({
			exceptions: this.state.exceptions
		}, this.sendStateUp);
	}

	render() {
		this.fieldItems = [];
		let fieldItems;
		fieldItems = this.state.values.map((value, i) => {
			if (i > 0 && this.state.minimized) return ""; // if field is minimized, only render one fieldItem
			return (
				<FieldItem
					ref={input=> {if (input == null) return; this.fieldItems.push(input); this.lastFieldItem = input; }}
					value={value}
					removeHandler={(i)=> this.removeFieldItem(i)}
					key={i}
					index={i}
					onUpdate={this.handleItemUpdate.bind(this)}
					onPaste={(e, i)=> this.pasteHandler(e, i)}
					onBlur={(valueIndex)=> this.props.onBlur(this.props.index, valueIndex)}
					onFocus={(valueIndex)=> this.props.onFocus(this.props.index, valueIndex)}
				/>
			);
		});
		return (
			<div className="field-container">
				<div className="field-top-bar">
					<button onClick={()=> this.props.removeHandler(this.props.uuid)} className="material-icons button-remove"></button>
					<EditableText
						className="field-title title-mid"
						ref={(title)=> this.editableTitle = title}
						value={this.state.name}
						onUpdate={(name)=> this.handleNameChange(name)}
						onFinishEdit={()=> this.handleFinishEditTitle()}
					/>
					<p onClick={()=> this.props.clickCycleHandler(this.props.index)} className={"material-icons button-cycle " + this.props.cycleColor}>link</p>
				</div>
				<div className="field-list-container">
					{fieldItems}
					<button onClick={this.addFieldItem.bind(this)} className="material-icons button-add"></button>
				</div>
				{
					this.state.exceptions.map((exception, i) => {
						return <Exception onUpdate={(value)=> this.updateException(i, value)} key={i} options={this.state.values} selected={exception}/>
					})
				}
			</div>
		);
	}
}
export default Field;
