import React from "react";
import Button from "../Button/Button.js";
import Field from "../Field/Field.js";
import Exception from "../Exception/Exception.js";
import UnitGenerator from "../../UnitGenerator.js";
import EditableText from "../EditableText/EditableText.js";
import Select from "../Select/Select.js";
import dataStructures from "../../dataStructures.js";
import uuid from "js-uuid";
import deleteIcon from "../../images/icons/icon-delete-dark.png";
import {SortableContainer, SortableElement, arrayMove} from "react-sortable-hoc";
import "./style.scss";

const SortableItem = SortableElement(({item}) => {
	return (
		<li className="SortableItem">
			{item}
		</li>
	);
});

const SortableList = SortableContainer(({items}) => {
	return (
		<ul className="SortableList">
			{items.map((item, index) => (
				<SortableItem key={`item-${index}`} index={index} item={item} />
			))}
		</ul>
	);
});

class BannerSet extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			uuid: this.props.uuid,
			name: this.props.name,
			cycles: this.props.cycles,
			fields: this.props.fields,
			unitListLength: this.props.unitListLength,
			exceptionListLength: this.props.exceptionListLength,
			exceptionListsLength: [],
			minimized: this.props.minimized,
			fileType: this.props.fileType
		};

		this.fields = []; // array to hold fields
		this.lastField = null;

		// colors used to indicate cycling pairs
		this.cycleColors = [
			"cycle-pink",
			"cycle-red",
			"cycle-green",
			"cycle-yellow",
			"cycle-purple"
		];
	}

	componentDidMount() {
		// if there is no data, add a "format" field
		if (this.state.fields.length == 0) this.addField({name:"Format", values: [""], uuid: uuid.v4()});
		this.generateUnitList(false);
	}

	addField(field) {
		this.state.fields.push(field);
		this.setState({
			fields: this.state.fields
		}, ()=> {
			// focus on the newly created field's name
			if (this.lastField)	this.lastField.editableTitle.toggleEdit();
			this.generateUnitList();
		});
	}

	removeField(uuid) {
		let fields = this.state.fields.filter((field, i) => {
			return uuid != field.uuid;
		});
		this.setState({
			fields: fields
		}, this.generateUnitList);
	}

	onSort(sorted) {
		this.setState({
			fields: arrayMove(this.state.fields, sorted.oldIndex, sorted.newIndex),
			cycles: [],
		}, this.generateUnitList);
	}

	handleUpdate(newState) {
		// check which field(s) has been updated
		this.state.fields.forEach((field, i) => {
			if (field.uuid == newState.uuid) this.state.fields[i] = newState;
		});

		// set state
		this.setState({
			fields: this.state.fields
		}, this.generateUnitList);
	}

	generateUnitList(modified = true) {

		// get the exceptions for this banner set
		this.exceptions = this.state.fields[0] ? this.state.fields[0].exceptions.map((value, i)=> {
			return this.state.fields.map(field => {
				return field.exceptions[i];
			})
		}) : [];

		// get the "raw" unit list (not excluding exceptions)
		let rawUnitList = UnitGenerator.getRawUnitList(this.state.fields, this.state.cycles);

		// get each individual exception list in an array
		let exceptionLists = this.exceptionLists = UnitGenerator.getExceptionLists(this.state.fields, this.exceptions, rawUnitList);

		// concat exception lists into one big list
		let exceptionList = this.exceptionList = [].concat(...exceptionLists);

		this.unitList = UnitGenerator.getUnitList(rawUnitList, exceptionList);
		this.setState({
			unitListLength: this.unitList.length,
			exceptionListLength: exceptionList.length,
			exceptionListsLength: exceptionLists.map(list => { return list.length; })
		}, modified ? this.sendStateUp : null);
	}

	sendStateUp() {
		this.props.onUpdate(this.state, this.props.index);
	}

	generateUnitPreview() {
		if (this.state.fields.length == 0) return "";

		return this.state.fields.map((field)=> {
			return field.values ? field.values[0] : "";
		}).filter((part)=> {
			return part != "";
		}).join("_");
	}

	componentWillReceiveProps(nextProps) {
		this.setState(nextProps);
	}
	addException() {
		this.state.fields.forEach(field => {
			field.exceptions.push([]);
		});
		this.setState({
			fields: this.state.fields
		}, this.generateUnitList);
	}

	removeException(index) {
		this.state.fields.forEach(field=> {
			field.exceptions.splice(index, 1);
		});
		this.setState({
			fields: this.state.fields
		}, this.generateUnitList);
	}

	clickCycleHandler(index) {
		if (typeof this.state.cyclingIndex == "number" && index != this.state.cyclingIndex) {
			this.setCycle(index);
		} else if (index == this.state.cyclingIndex) {
			this.removeCycle(index);
		} else {
			this.beginCycle(index);
		}
	}

	beginCycle(index) {
		this.setState({
			cyclingIndex: index
		});
	}

	setCycle(index) {
		// check if either indexes exist within a cycle array
		// if so, add the one which didn't exist
		let cycle1 = this.findCycle(this.state.cyclingIndex);
		let cycle2 = this.findCycle(index);

		if (cycle1 && cycle2) {
			console.log("cycle combination already exists, do nothing.")
		} else if (cycle1) {
			console.log("push index");
			cycle1.push(index);
		} else if (cycle2) {
			console.log("push cyclingIndex");
			cycle2.push(this.state.cyclingIndex);
		} else {
			console.log("cycle combination doesn't exist. Create new one.");
			this.state.cycles.push([this.state.cyclingIndex, index]);
		}

		// sort cycles
		this.state.cycles.forEach(cycle => {
			cycle.sort();
		});

		this.setState({
			cycles: this.state.cycles,
			cyclingIndex: null
		}, this.generateUnitList);
	}

	removeCycle(index) {
		let cycle = this.findCycle(index);
		let i = this.findCycleIndex(index);
		cycle.splice(cycle.findIndex(i => {return i == index; }), 1);

		// if there are no fields left in cycle, remove it
		if (cycle.length <= 1) this.state.cycles.splice(i, 1);

		this.setState({
			cycles: this.state.cycles,
			cyclingIndex: null
		}, this.generateUnitList);
	}

	findCycle(index) {
		return this.state.cycles.find(cycle => {
			return cycle.indexOf(index) != -1;
		});
	}

	findCycleIndex(cycle) {
		return this.state.cycles.findIndex(currentCycle => {
			return cycle == currentCycle;
		});
	}

	handleNameChange(name) {
		this.setState({name}, this.sendStateUp);
	}

	handleFileTypeChange(fileType) {
		this.setState({fileType}, this.sendStateUp);
	}

	render() {
		this.fields = [];

		return (
			<div className={"bannerset grey-background"}>
				<div className="bannerset-header">

					<div className="bannerset-header-left">
						<p onClick={()=> this.setState({minimized: !this.state.minimized}, this.sendStateUp)} className="material-icons button-minimize" style={{transform: !this.state.minimized ? "rotate(90deg)" : ""}}>play_arrow</p>
						<EditableText
							className="title-mid"
							value={this.state.name}
							onUpdate={(e)=> this.handleNameChange(e)}
						/>
						<h2 className="title-mid"> / </h2>
						<Select
							className="title-mid"
							options={[
								".jpg",
								".png",
								".gif",
								".html",
								".mp4",
								".mov"
							]}
							value={this.state.fileType}
							onUpdate={(e)=> this.handleFileTypeChange(e)}
						/>
						<h2 className="title-mid"> / </h2>
						<h2 className="title-mid">{this.state.unitListLength + " units"}</h2>
					</div>
					<div className="bannerset-header-center">
						<h2 className="unit-name-preview title-mid">{this.generateUnitPreview()}</h2>
					</div>

					<div className="bannerset-header-right">
						<h2 onClick={()=> this.props.duplicateHandler(this.props.index)} className="material-icons button-copy">content_copy</h2>
						<img onClick={this.props.removeHandler.bind(this.props.removeHandlerScope, this.props.index)} src={deleteIcon} className="button-delete" />
					</div>

				</div>
				<div className="bannerset-container" style={{display: this.state.minimized ? "none" : "block"}}>
					<div className="bannerset-container-flex">
						<div className="fields-container">
							{
								this.state.cycles.map(cycle => {
									return cycle.map((fieldIndex, i) => {
										if (cycle[i + 1]) {
											return <div key={i} className="cycle-visualizer" style={{ left: ((fieldIndex + 1) * 130) - 15, width: (cycle[i + 1] - fieldIndex) * 130 }}></div>
										}
									});
								})
							}
							<SortableList items={
								this.state.fields.map((field, i) => {
									// select a color on the cycle button from the cycle colors based on the index of the field
									let cycleColor = this.state.cyclingIndex == i ? "cycle-blue" : this.cycleColors[this.state.cycles.findIndex(cycle => cycle.includes(i))];
									return <Field
										ref={(field)=> {if (field == null) return; this.fields.push(field); this.lastField = field; }}
										removeHandler={(uuid)=> this.removeField(uuid)}
										clickCycleHandler={(index)=> this.clickCycleHandler(index)}
										clickHandler={()=>{}}
										uuid={field.uuid}
										name={field.name}
										values={field.values}
										cycleColor={cycleColor}
										bind={field.bind}
										key={field.uuid}
										index={i}
										onUpdate={this.handleUpdate.bind(this)}
										exceptions={field.exceptions}
										onBlur={(fieldIndex, valueIndex)=> this.props.onBlur(this.props.index, fieldIndex, valueIndex)}
										onFocus={(fieldIndex, valueIndex)=> this.props.onFocus(this.props.index, fieldIndex, valueIndex)}
									/>
								})
							} onSortEnd={this.onSort.bind(this)} axis="x" lockAxis="x" distance={10}/>
						</div>
						<div className="fields-right-container">
							<div className="column-add">
								<button className="material-icons button-add" onClick={
									this.addField.bind(this, dataStructures.field({
										name: "New Field " + (this.state.fields.length + 1),
										exceptions: this.state.fields[0] ? this.state.fields[0].exceptions.map(exception => { return ""; }) : []
									}))
								}></button>
								<h2 className="title-small">COLUMN</h2>
							</div>
							<div className="fields-lower-right-container">
								{
									this.state.exceptionListsLength.map((length, i) => {
										return (
											<div key={"a" + i} className="exception-buttons-container">
												<h2 onClick={()=> this.removeException(i)} key={"b" + i} className="material-icons button-delete">clear</h2>
												<div onClick={()=> this.props.previewExceptionHandler(this.exceptionLists[i])} key={"c" + i} className="exception-preview-button">
													<p className="title-small">{length}</p>
													<p className="material-icons exception-preview-icon">search</p>
												</div>
											</div>
										);
									})
								}
							</div>
						</div>
					</div>
					<div className="fields-lower-container" style={{transform: "translate(0, -" + ((this.state.fields[0].exceptions.length * 25) + 15) + "px)"}}>
						<button className="material-icons button-add" onClick={this.addException.bind(this)}></button>
						<h2 className="title-small">EXCEPTION</h2>
					</div>
				</div>
			</div>
		);
	}
}

export default BannerSet;
