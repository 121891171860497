import uuid from "js-uuid";

let dataStructures = {
	field(options) {
		return Object.assign({
			name: "New Field",
			values: [""],
			exceptions: [],
			uuid: uuid.v4()
		}, options);
	},

	bannerSet(options) {
		return Object.assign({
			name: "New Bannerset",
			fileType: ".jpg",
			fields: [{name: "format", values: [""], exceptions: [], uuid: uuid.v4()}],
			cycles: [],
			unitListLength: 0,
			exceptionListLength: 0,
			minimized: false,
			uuid: uuid.v4()
		}, options);
	},

	project(options) {
		return Object.assign({
			name: "New Project",
			client: "New Client",
			clientLowerCase: options.client.toLowerCase() || "new client",
			nameLowerCase: options.name.toLowerCase() || "new project",
			sets: [this.bannerSet()]
		}, options);
	}
};

export default dataStructures;
