import React from "react";
import "./style.scss";

class Overlay extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		};
	}

	render() {
		return (
			<div className="overlay-container">
				<div className={"overlay " + this.props.className || ""}>
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default Overlay;
