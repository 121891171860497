import React from "react";
import BannerSet from "../BannerSet/BannerSet.js";
import UnitGenerator from "../../UnitGenerator.js";
import UnitListView from "../UnitListView/UnitListView.js";
import ExceptionListView from "../ExceptionListView/ExceptionListView.js";
import dataStructures from "../../dataStructures.js";
import uuid from "js-uuid";
import SavingIndicator from "../SavingIndicator/SavingIndicator.js";
import "./style.scss";

class Project extends React.Component {

	constructor(props) {
		super(props);

		this.bannerSets = [];
		this.state = {
			focusIndex: null,
			saving: false,
			sets: [],
			showUnitList: false,
			showExceptionList: false,
			unitLists: {}
		};
		this.ref = this.props.database.collection("projects").doc(this.props.id);

		this.ref.get().then((doc)=> {
			let data = doc.data();
			// set state with data transformed from firebase style to what we want
			this.setState(this.filterFromFirebase(data));
		}).catch((error)=> {
			console.log("Error getting document", error);
		});
	}

	componentDidMount() {
		window.addEventListener("keydown", this.keyDownHandler.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener("keydown", this.keyDownHandler);
	}

	updateFirebase(data) {
		this.setState({saving: true});
		this.ref.set(this.filterForFirebase(data)).then(()=> {
			this.setState({saving: false});
		});
	}

	addBannerSet() {
		this.state.sets.push(dataStructures.bannerSet({
			name: "New Bannerset " + (this.state.sets.length + 1),
		}));
		this.setState({sets: this.state.sets});
	}

	filterForFirebase(data) {
		// this method removes all unnecessary state properties and remaps data before uploading to firebase
		let uploadData = {
			sets: data.sets.map(set => {
				return {
					name: set.name,
					uuid: set.uuid,
					fileType: set.fileType,
					cycles: set.cycles.map(cycle => {
						return {cycle: cycle};
					}),
					fields: set.fields.map(field => {
						return {
							name: field.name,
							uuid: field.uuid,
							values: field.values,
							exceptions: field.exceptions.map(exception => {
								return {values: exception};
							})
						};
					}),
					minimized: set.minimized
				};
			}),
			name: data.name,
			client: data.client,
			nameLowerCase: data.name.toLowerCase(),
			clientLowerCase: data.client.toLowerCase(),
			modified: Date.now(),
			modifiedBy: firebase.auth().currentUser.displayName
		};
		return uploadData;
	}

	filterFromFirebase(data) {
		// this method retransform some data from required firebase structure to preferred app structure
		data.sets.forEach((set) => {
			set.cycles.forEach((cycle, i) => {
				set.cycles[i] = cycle.cycle;
			});

			set.fields.forEach(field => {
				field.exceptions = field.exceptions.map(exception => {
					return exception.values;
				});
			});
		});

		return data;
	}

	handleUpdate(newState, itemIndex) {
		// collect all data from all fields
		this.state.sets[itemIndex] = newState;
		this.setState({sets: this.state.sets}, this.updateFirebase(this.state));
	}

	generateUnitList() {
		// generate unit lists
		this.unitLists = this.bannerSets.map(set => {
			return {
				name: set.props.name,
				list: set.unitList
			}
		});

		this.exceptionLists = this.bannerSets.map(set => {
			return {
				name: set.props.name,
				list: set.exceptionList
			}
		});

		this.setState({showUnitList: true});
	}

	hideUnitList() {
		this.setState({showUnitList: false});
	}

	componentWillReceiveProps(nextProps) {
		this.setState(nextProps.data);
	}

	removeSet(index) {
		if (!confirm("Are you sure?")) return;
		this.state.sets.splice(index, 1);
		this.setState({
			sets: this.state.sets
		}, this.updateFirebase(this.state));
	}

	duplicateSet(index) {
		let oldSet = this.state.sets[index];
		// make sure copy is a deep copy and create new uuid's
		let newSet = {
			name: "New Bannerset " + (this.state.sets.length + 1),
			fields: oldSet.fields.map(field => {
				return { name: field.name, values: field.values.slice(), exceptions: field.exceptions.slice(), minimized: false, uuid: uuid.v4() }
			}),
			cycles: oldSet.cycles.map(cycle => { return cycle.slice()}),
			unitListLength: oldSet.unitListLength,
			exceptionListLength: oldSet.exceptionListLength,
			minimized: false,
			fileType: oldSet.fileType,
			uuid: uuid.v4()
		};
		this.state.sets.push(newSet);
		this.setState({
			sets: this.state.sets
		}, this.updateFirebase(this.state));
	}

	saveRef(component) {
		if (component) this.bannerSets[component.props.index] = component;
	}

	fieldBlurHandler(setIndex, fieldIndex, valueIndex) {
		let fi = this.state.focusIndex;
		let selectedField = this.bannerSets[fi.set].fields[fi.field];
		let currentValue = this.state.sets[fi.set].fields[fi.field].values[fi.value];

		// if fieldItem is empty, not the first one and a field is selected, remove it
		if (currentValue == "" && (valueIndex > 0 || this.state.sets[fi.set].fields[fi.field].values.length > 1) && selectedField) selectedField.removeFieldItem(valueIndex);

		this.setState({
			focusIndex: null
		});
	}

	fieldFocusHandler(setIndex, fieldIndex, valueIndex) {
		this.setState({
			focusIndex: {set: setIndex, field: fieldIndex, value: valueIndex}
		});
	}

	keyDownHandler(e) {
		let fi = this.state.focusIndex;
		// if there is no focus, return
		if (fi == null) return;
		let selectedField = this.bannerSets[fi.set].fields[fi.field];
		let currentValue = this.state.sets[fi.set].fields[fi.field].values[fi.value];
		let desiredFieldItem;

		// handle switching between field items with keypresses
		switch (e.key) {
			case "Enter":
				desiredFieldItem = selectedField.fieldItems[fi.value + 1];

				// if there exists a next field item
				if (desiredFieldItem) {
					// focus on it
					desiredFieldItem.inputField.focus();
				} else if (currentValue != "") {
					// create a new one
					selectedField.addFieldItem();
				}
			break;
			case "Backspace":
				// if field item is empty and user presses backspace, remove it
				if (currentValue == "" && (fi.value > 0 || this.state.sets[fi.set].fields[fi.field].values.length > 1)) {
					e.preventDefault();
					selectedField.removeFieldItem(fi.value);
				}
			break;
			case "ArrowDown":
				e.preventDefault();
				desiredFieldItem = selectedField.fieldItems[fi.value + 1];
				if (desiredFieldItem) desiredFieldItem.inputField.focus();
				if (currentValue == "") selectedField.fieldItems[fi.value].inputField.focus();
			break;
			case "ArrowUp":
				e.preventDefault();
				desiredFieldItem = selectedField.fieldItems[fi.value - 1];
				if (desiredFieldItem) desiredFieldItem.inputField.focus();
			break;
		}
	}

	render() {
		// clear bannersets array (it will be populated on render)
		this.bannerSets = [];
		return (
				<div className="project-container">
					<div className="top-bar">
						<div className="top-bar-left">
							<h2 onClick={()=> this.props.closeHandler()} className="material-icons title back-arrow">keyboard_arrow_left</h2>
							<h2 className="title">{this.state.client}</h2>
							<h2 className="title">-</h2>
							<h2 className="title">{this.state.name}</h2>
						</div>
						<div className="top-bar-center">
							<button onClick={()=> this.generateUnitList()} id="button-generate-unit-list"><h2>GENERATE UNIT LIST</h2><h2 className="material-icons">list</h2></button>
							<button onClick={()=> this.addBannerSet()} className="button-add material-icons"></button>
						</div>
					</div>
					{
						this.state.sets.map((set, i)=> {
							return (
								<BannerSet
									ref={this.saveRef.bind(this)}
									cycles={set.cycles}
									fields={set.fields}
									name={set.name}
									fileType={set.fileType}
									key={i}
									order={i % 2}
									index={i}
									uuid={set.uuid}
									minimized={set.minimized}
									removeHandler={this.removeSet}
									removeHandlerScope={this}
									duplicateHandler={(index)=> this.duplicateSet(index)}
									onUpdate={this.handleUpdate.bind(this)}
									onBlur={(setIndex, fieldIndex, valueIndex)=> this.fieldBlurHandler(setIndex, fieldIndex, valueIndex)}
									onFocus={(setIndex, fieldIndex, valueIndex)=> this.fieldFocusHandler(setIndex, fieldIndex, valueIndex)}
									previewExceptionHandler={(list)=> this.setState({showExceptionList: list})}
								/>
							)
						})
					}
					{this.state.showUnitList || this.state.showExceptionList ?
						<div id="dark-overlay"></div>
						: ""
					}
					{this.state.showUnitList ?
						<UnitListView
							name={this.props.name}
							unitLists={this.unitLists}
							exceptionLists={this.exceptionLists}
							selected={[this.unitLists[0].name]}
							closeHandler={this.hideUnitList.bind(this)}
						/> : ""
					}
					{this.state.showExceptionList ?
						<ExceptionListView
							list={this.state.showExceptionList}
							closeHandler={()=> this.setState({showExceptionList: false})}
						/> : ""
					}
					<SavingIndicator saving={this.state.saving}/>
				</div>
		);
	}
}

export default Project;
