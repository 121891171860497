import React from "react";
import "./style.scss";

class EditableText extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this.props.value || "",
			editing: false
		};

		this.keyDownHandler = this.keyDownHandler.bind(this);
	}

	focusHandler() {
		window.addEventListener("keypress", this.keyDownHandler);
	}

	blurHandler() {
		window.removeEventListener("keypress", this.keyDownHandler);
		if (this.props.onBlur) this.props.onBlur(this.state.value);
	}

	componentWillReceiveProps(nextProps) {
		this.setState(nextProps);
	}

	keyDownHandler(e) {
		// if we are in edit state, exit edit state
		if (this.state.editing && e.key == "Enter") {
			this.input.blur();
			this.setState({editing: false});
			if (this.props.onFinishEdit) this.props.onFinishEdit();
		}
	}

	handleChange(event) {
		this.setState({value: event.target.value}, function() {
			if (this.props.onUpdate) this.props.onUpdate(this.state.value);
		});
	}

	toggleEdit() {
		if (this.props.disabled) return;
		// toggle edit state, then select field title input
		this.setState({editing: !this.state.editing}, ()=> {
			if (!this.input) return;
			this.input.focus();
			this.input.select();
		});
	}

	render() {
		return (
			<div className="editable-text-container">
				{
					this.state.editing && !this.props.disabled ?
					<input
						className={this.props.className}
						type="text"
						ref={input => this.input = input}
						value={this.state.value}
						onChange={(e)=> this.handleChange(e)}
						onBlur={()=> {this.blurHandler(); this.toggleEdit();}}
						onFocus={()=> this.focusHandler()}
						size={this.state.value.length}
					/> :
					<h2 className={this.props.className} onClick={(e)=> {e.stopPropagation(); this.toggleEdit(); }}>{this.state.value}</h2>
				}
			</div>
		);
	}
}
export default EditableText;
