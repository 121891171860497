import React from "react";
import "./style.scss";

class SavingIndicator extends React.Component {

	render() {
		return (
			<div className={"saving-indicator" + (this.props.saving ? " saving" : "")}>saving...</div>
		);
	}
}
export default SavingIndicator;
