import React from "react";
import "./style.scss";

class Tabs extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: this.props.selected
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			selected: nextProps.selected
		});
	}

	componentDidMount() {
		window.addEventListener("keydown", this.keyDownHandler.bind(this));
		window.addEventListener("keyup", this.keyUpHandler.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener("keydown", this.keyDownHandler);
		window.removeEventListener("keyup", this.keyUpHandler);
	}

	keyDownHandler(e) {
		if (e.key == "Shift") this.multiSelect = true;
	}

	keyUpHandler(e) {
		if (e.key == "Shift") this.multiSelect = false;
	}

	clickTabHandler(value) {

		// handle multiselect
		if (this.multiSelect) {
			this.state.selected.push(value);
		} else {
			this.state.selected = [value];
		}

		// set state
		this.setState({
			selected: this.state.selected
		}, this.props.onSelect(this.state.selected));
	}

	render() {
		return (
			<div className="tabs-container">
				{
					this.props.values.map((value, i) => {
						return (
							<div key={i} className="tab" onClick={()=> this.clickTabHandler(value)}>
								<div className={"tab-border" + (this.state.selected.includes(value) ? " selected" : "")}></div>
								<div className="tab-content">
									{value}
								</div>
							</div>
						)
					})
				}
			</div>
		);
	}
}

export default Tabs;
