import "./style.scss";
import React from "react";

class Button extends React.Component {
	constructor(props) {
		super(props);
		// this.callback = callback;
		// this.addEventListener(callback);
	}

	render() {
		return (<button onClick={this.props.onClick} className="button buttonText">
			{this.props.value}
		</button>);
	}
}

export default Button;