import React from "react";
import uuid from "js-uuid";
import "./style.scss";

class MultiSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: this.props.selected,
			editing: false,
			search: ""
		};

		this.containerId = uuid.v4();
		this.searchId = uuid.v4();
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			selected: nextProps.selected
		});
	}

	handleChange(value) {
		if (this.state.selected.includes(value)) {
			// if value already selected, remove it
			this.state.selected = this.state.selected.filter(v => {
				return v !== value;
			});
		} else {
			// otherwise, add value
			this.state.selected.push(value);
		}

		this.setState({
			selected: this.state.selected
		}, ()=> this.props.onUpdate(this.state.selected));
	}

	toggleAll() {
		this.setState({
			selected: this.state.selected.length < this.props.options.length ? this.props.options : []
		}, ()=> this.props.onUpdate(this.state.selected));
	}

	render() {

		let selectedString;
		if (this.state.selected) {
			selectedString = this.state.selected.join(", ");
		} else {
			selectedString = "Select...";
		}
		if (selectedString.length > 20) selectedString = this.state.selected.length + " selected";
		return (
			<div ref={container => this.container = container} tabIndex="0" onBlur={(e)=> {if (e.relatedTarget == null || e.relatedTarget.id != this.searchId) this.setState({editing: false}); }} className="multi-select-container" id={this.containerId}>
				<div onClick={(e)=> this.setState({editing: !this.state.editing})} className="multi-select-value">
					<p className="title-small">
						{selectedString}
					</p>
					<p className="material-icons multi-select-arrow">arrow_drop_down</p>
				</div>
				{
					this.state.editing ?
						<ul className="multi-select-dropdown">
							<li onClick={(e)=> this.toggleAll()} className="multi-select-option title-small">{this.state.selected.length < this.props.options.length ? "Select all" : "Deselect all"}</li>
							<li className="multi-select-option">
								<input onChange={(e)=> this.setState({search: e.target.value})} onBlur={e => {e.stopPropagation(); if (e.relatedTarget == null || e.relatedTarget.id != this.containerId) this.setState({editing: false}); }} className="title-small" id={this.searchId} type="text" placeholder="Search"/>
							</li>
							{this.props.options.map((option, i) => {
								let selected = this.state.selected.includes(option);
								if (this.state.search !== "" && option.indexOf(this.state.search) == -1) return;
								return <li onClick={(e)=> this.handleChange(option)} className={"multi-select-option title-small" + (selected ? " selected" : "")} key={i}>{option}</li>
							})}
						</ul>
					: ""
				}
			</div>
		);
	}
}
export default MultiSelect;
