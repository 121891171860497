import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App/App.js";
import firebaseConfig from "./firebase/config.js";
import "./style.scss";

export default function() {

	// Initialize Firebase
	firebase.initializeApp(firebaseConfig);

	const provider = new firebase.auth.GoogleAuthProvider();

	firebase.auth().onAuthStateChanged(function(user) {
		console.log('user', user);
		if (user) {
			// if (getEmailDomain(user.email) === 'bannerboy.com') {
				this.database = firebase.firestore();
				ReactDOM.render(
					(
						<App database={this.database}/>
					),
					document.getElementById("root")
				);
			// } else {
			// 	ReactDOM.render(('Not signed in.'), document.getElementById("root"));
			// 	user.delete();
			// }
		} else {
			ReactDOM.render(('Not signed in.'), document.getElementById("root"));
			firebase.auth().signInWithPopup(provider).then(function(result) {
				console.log('result', result);
				// // only allow bannerboy.com domains
				// let domain = getEmailDomain(result.user.email);
				// if (domain !== 'bannerboy.com') firebase.auth().signOut();
			});
		}
	});

	function getEmailDomain(email) {
		return email.replace(/.*@/g, '');
	}
}