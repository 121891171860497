const UnitGenerator = {
	getRawUnitList(_fields, cycles) {

		let fields = _fields.filter(field => {
			// filter out fields with no values
			return field.values.length > 0;
		}).map(field => {
			// remove values that are empty
			return field.values.filter(value => { return value != ""; });
		});

		// generate unit list
		let results = this.combinations(fields);
		let cycleResults = this.cycle(fields, cycles);
		let filteredResults = this.filterResultsWithCycles(results, cycleResults, cycles);

		return filteredResults;
	},

	getUnitList(rawUnitList, exceptionList) {
		// check against exceptionList and remove any exceptions
		return rawUnitList.filter((unit)=> {
			let keepUnit = true;
			exceptionList.forEach((exception)=> {
				if (unit == exception) keepUnit = false;
			});
			return keepUnit;
		});
	},

	getExceptionLists(_fields, _exceptions, rawUnitList) {
		// if every exception item is empty, return
		if (_exceptions.every(exception => {
			return exception.length == 0;
		})) return [];

		// if field is left blank, consider it full
		let exceptions = _exceptions.map((exception) => {
			return exception.map((values, i) => {
				if (values.length == 0) return _fields[i].values;
				return values;
			});
		});

		let unfilteredExceptions = exceptions.map(exception => {
			return this.combinations(exception);
		});

		// only return exceptions that actually exist in the unit list
		return unfilteredExceptions.map(exception => {
			return exception.filter(unitName => {
				return rawUnitList.includes(unitName);
			});
		});
	},

	getExceptionList(_fields, _exceptions) {
		// if every exception item is empty, return
		if (_exceptions.every(exception => {
			return exception.length == 0;
		})) return [];

		// if field is left blank, consider it full
		let exceptions = _exceptions.map((exception) => {
			return exception.map((values, i) => {
				if (values.length == 0) return _fields[i].values;
				return values;
			});
		});

		let exceptionList = [];
		exceptions.forEach((exception)=> {
			this.combinations(exception).forEach((unit)=> {
				exceptionList.push(unit);
			});
		});
		return exceptionList;
	},

	cycle(fields, cycles) {
		let cycleResults = cycles.map(cycle => {
			// let cycledFields = cycle.map(index => {
			// 	return fields[index];
			// });
			return this.cycleCombinations(fields, cycle);
		});
		return cycleResults;
	},

	combinations(fields) {
		if (fields.length == 0) return [];
		if (fields.length == 1) return fields[0];

		let result = [];
		let tail = this.combinations(fields.slice(1)); // recur with all fields except first

		// loop through tail
		tail.forEach((field, i) => {
			// loop through first field in tail
			fields[0].forEach((value, j) => {
				result.push(fields[0][j] + "_" + tail[i]); // push each value of the first field in tail
			});
		});

		return result;
	},

	cycleCombinations(fields, cycle) {
		let longestField = fields.map(function(a){return a.length;}).indexOf(Math.max.apply(Math, fields.map(function(a){return a.length;})));

		/*
			loop through fields and combine them "horizontally" as many times as the field with the most values, like so:
		  y --- -> --- -> ---
		  v --- -> --- -> ---
		    --- -> --- -> ---
			x >
		*/
		let result = [""], x = 0, y = 0;
		let longestFieldLength = fields[longestField].length;
		let length = longestFieldLength * fields.length;
		for (let i = 0; i < length; i++) {
			// do stuff
			let firstPart = false;
			if (!result[y]) {
				result[y] = "";
				firstPart = true;
			}

			// search for x index in cycle, if it exists, add value, otherwise add empty
			if (cycle.find(index => { return x == index; }) != undefined) {
				// result[y].push(fields[x][y % fields[x].length]);
				result[y] += (firstPart ? "_" : ".*_") + fields[x][y % fields[x].length] + "_";
			}

			// increase indexes
			x++;

			// if we have looped x, increase y
			if (x >= fields.length) {x = 0; y++;}
		}
		return result;
	},

	filterResultsWithCycles(results, cycleResults, cycleInfos) {
		if (cycleResults.length == 0) return results;

		return results.filter(unitName => {
			// for each unit

			// THIS IS STUPID. WRITE BETTER REGEX
			unitName = "_".concat(unitName).concat("_");
			unitName = unitName.replace(/_/g, "__");

			// all cycleResults must match
			return cycleResults.every(cycleResult => {
				return cycleResult.find(cycleName => {
					return unitName.search(new RegExp(cycleName, "g")) != -1;
				}) != undefined;
			});
		});
	}
}

export default UnitGenerator;
