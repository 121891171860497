import React from "react";
import DownloadableFile from "../../utils/DownloadableFile.js";
import Overlay from "../Overlay/Overlay.js";
import Tabs from "../Tabs/Tabs.js";
import List from "../List/List.js";
import "./style.scss";

class UnitListView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: this.props.selected
		};
		this.state.unitList = this.compileList(this.props.unitLists);
		this.state.exceptionList = this.compileList(this.props.exceptionLists);

		new Clipboard("#button-copy-unit-list");
	}

	download() {
		DownloadableFile.download(this.state.selected.join("_") + ".txt", this.compileList(this.props.unitLists).join("\n"));
	}

	selectHandler(values) {
		this.setState({
			selected: values
		}, ()=> {
			this.setState({
				unitList: this.compileList(this.props.unitLists),
				exceptionList: this.compileList(this.props.exceptionLists)
			});
		});
	}

	compileList(lists) {
		return [].concat(...lists.filter(list => {
			return this.state.selected.includes(list.name);
		}).map(list => {
			return list.list;
		}))
	}

	selectAll() {
		this.selectHandler(this.props.unitLists.map(unitList => {return unitList.name; }));
	}

	render() {
		return (
			<Overlay>
				<div className="unit-list-top-bar">
					<div className="unit-list-top-bar-left">
						<h2 onClick={()=> this.selectAll()} className="title-small">SELECT ALL</h2>
					</div>
					<div className="unit-list-top-bar-right">
						<h2 className="title-small">{this.state.unitList.length}</h2>
						<button id="button-copy-unit-list" data-clipboard-target="#unit-list" className="unit-list-button title-small">COPY</button>
						<button className="unit-list-button title-small" onClick={()=> this.download()}>DOWNLOAD</button>
						<button className="material-icons button-unit-list-close" onClick={()=> this.props.closeHandler()}>clear</button>
					</div>
				</div>
				<div className="unit-list-lower-container">
					<Tabs values={this.props.unitLists.map(unitList => {return unitList.name })} selected={this.state.selected} onSelect={(values)=> this.selectHandler(values)}/>
					<div className="list-view">
						<div className="list-view-column left">
							<h2 className="title-small">ALL UNITS</h2>
							<List id="unit-list" values={this.state.unitList}/>
						</div>
						<div className="list-view-column right">
							<h2 className="title-small">EXCEPTIONS</h2>
							<List values={this.state.exceptionList}/>
						</div>
					</div>
				</div>
			</Overlay>
		);
	}
}

export default UnitListView;
