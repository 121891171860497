import React from "react";
import Select from "react-select";
import MultiSelect from "../MultiSelect/MultiSelect.js";
import "./style.scss";

class Exception extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: this.props.selected
		};
	}

	handleChange(selected) {
		this.setState({
			selected
		}, this.props.onUpdate(selected));
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			selected: nextProps.selected
		});
	}

	render() {
		return (
			<div className="exception-item">
				<MultiSelect
					options={this.props.options}
					selected={this.state.selected}
					onUpdate={(selected)=> this.handleChange(selected)}
				/>
			</div>
		);
	}
}
export default Exception;
