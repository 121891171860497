import React from "react";
import Overlay from "../Overlay/Overlay.js";
import List from "../List/List.js";
import "./style.scss";

class ExceptionListView extends React.Component {

	render() {
		return (
			<Overlay className="exception-list">
				<div className="exception-list-top-bar">
					<h2 className="title-mid">EXCEPTIONS</h2>
					<div className="exception-list-top-bar-right">
						<button className="material-icons button-exception-list-close" onClick={()=> this.props.closeHandler()}>clear</button>
					</div>
				</div>
				<div className="exception-list-lower-container">
					<div className="list-view">
						<div className="list-view-column left">
							<List id="exception-list" values={this.props.list}/>
						</div>
					</div>
				</div>
			</Overlay>
		);
	}
}

export default ExceptionListView;
