import React from "react";
import "./style.scss";

class Select extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this.props.value
		};
	}

	handleChange(event) {
		this.setState({value: event.target.value}, function() {
			if (this.props.onUpdate) this.props.onUpdate(this.state.value);
		});
	}

	render() {
		return (
			<select className={"select " + this.props.className} onChange={(e)=> this.handleChange(e)} value={this.state.value}>
				{
					this.props.options.map((option, i) => {
						return <option key={i} value={option}>{option}</option>
					})
				}
			</select>
		);
	}
}
export default Select;
