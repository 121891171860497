import React from "react";
import "./style.scss";

class List extends React.Component {

	render() {
		return (
			<ul id={this.props.id} className="list">
				{
					this.props.values.map((value, i) => {
						return (
							<li key={i} className="list-item">{value}</li>
						)
					})
				}
			</ul>
		);
	}
}

export default List;
